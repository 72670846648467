.c-tag-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.c-tag-links__tag {
  font-family: $heading-light;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;

  a {
    display: block;
  }

  @media (min-width: $md) {
    font-size: 1rem;
  }
}

.c-posts-grid__tag,
.c-tag-links__tag a {
  font-family: $heading-light;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: var(--primary);
  color: var(--black);
  transition:
    background-color 200ms,
    color 200ms,
    transform 200ms;
  padding: 0 rem(6px);
  text-decoration: none;
  border-radius: 0.2rem;

  @media (min-width: $md) {
    font-size: 1rem;
  }
}

.c-tag-links__tag a {
  background-color: var(--primary);
  color: var(--textColorInverse);

  &:hover,
  &:focus {
    color: var(--primary);
    background-color: var(--textColor);
    transform: scale(1.1);
  }
}

.c-tag-links--home {
  @media (max-width: $md) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    text-align: center;

    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      padding: 0.5rem;
    }

    .c-tag-links__tag {
      &:last-child a {
        border: 1px solid;
      }

      &:nth-child(n + 7):not(:last-child) {
        display: none;
      }
    }
  }

  .c-tag-links__tag {
    &:last-child {
      a {
        background: none;
        color: var(--textColor);
        text-decoration: underline;

        &:hover,
        &:focus {
          color: var(--textColor);
        }
      }
    }
  }
}

.c-posts-grid__tag--note,
.c-tag-links__tag--note a,
.c-tag-links__tag--quick-tip a,
.c-posts-grid__tag--quick-tip,
.c-posts-grid__tag--demo,
.c-tag-links__tag--demo a,
.c-posts-grid__tag--source {
  font-weight: 700;
  background-color: var(--note, royalblue);
  color: var(--white);
}

.c-posts-grid__tag--quick-tip,
.c-tag-links__tag--quick-tip a {
  background-color: var(--note, turquoise);
  color: var(--black);
}

.c-posts-grid__tag--demo,
.c-tag-links__tag--demo a {
  --accentColor: orange;
  background-color: var(--accentColor);
  color: var(--black);
}

.c-posts-grid__tag--source {
  color: var(--black);
}
