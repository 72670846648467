body {
  background-color: var(--bg);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: $wrapper;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: $md) {
    padding: 0 2rem;
  }

  @media (min-width: $lg) {
    padding: 0 4rem;
  }

  @media (min-width: 90em) {
    max-width: rem(1600px);
  }
}

.container-sm {
  width: 100%;
  max-width: $wrapper;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: $md) {
    padding: 0 2rem;
  }

  @media (min-width: $lg) {
    padding: 0 4rem;
  }
}

.page-content {
  padding: 0 0 2rem 0;
  flex-grow: 1;

  @media (min-width: $md) {
    padding: 0 0 6rem 0;
  }
}

.page-content--page {
  padding: 0 0 2rem 0;

  @media (min-width: $md) {
    padding: 3rem 0 6rem 0;
  }

  @media (min-width: $lg) {
    padding: 8rem 0 6rem 0;
  }
}

.post-grid {
  @media (min-width: $lg) {
    display: grid;
    gap: calc(2 * var(--vr));
    grid-template-columns:
      [main-start] minmax(0, 58rem)
      [main-end] minmax(rem(250px), 1fr);
  }
}

.post-grid__header {
  grid-column: 1 / -1;

  @media (min-width: $md) {
    margin-top: 6rem;
  }
}

.post-grid__main {
  @media (min-width: $lg) {
    grid-column: main;
  }
}

.post-grid__prev-next {
  @media (min-width: $lg) {
    grid-column: 1 / -1;
  }
}

/* Home */

.grid {
  @media (min-width: $lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--vr) rem(40px);
  }
}

.grid__tags {
  @media (min-width: $lg) {
    grid-column: 1 / 4;
  }
}

.grid__featured-post {
  margin-top: 1rem;

  @media (min-width: $md) {
    margin-top: 2rem;
  }

  @media (min-width: $lg) {
    grid-column: 4 / -1;
    margin-top: calc(var(--vr) * -6);
  }
}

.home-layout {
  padding-bottom: calc(var(--vr) * 2);
}

.home-layout__posts {
  display: grid;
  gap: var(--vr);
  position: relative;

  @media (min-width: $lg) {
    grid-template-columns: minmax(20rem, 1fr) 2fr;
    gap: 3rem;
    padding-top: calc(var(--vr) * 2);
  }
}

.home-layout__tags-wrapper {
  max-width: 40rem;

  @media (min-width: $lg) {
    position: sticky;
    top: 5rem;
  }
}

.home-layout__tags-title {
  margin: 0 0 calc(var(--vr)) 0;
}

.home-layout__bg-logo {
  max-width: 10rem;
  width: 20vw;
  position: absolute;
  top: -1%;
  left: -1%;
  height: auto;
}

.page-content {
  padding-bottom: calc(var(--vr) * 2);
}

.home-layout--no-hero {
  @media (min-width: $md) {
    padding-top: 6rem;
  }
}

.home-layout__expander-button {
  font-family: $heading-light;
  appearance: none;
  border: none;
  font-size: inherit;
  background: none;
  padding: 0.75rem 1rem;
  display: flex;
  cursor: pointer;
  background-color: var(--bgGrey);
  color: var(--textColor);
  border-radius: 0.3rem;
  margin-bottom: var(--vr);

  span {
    margin-left: 0.5rem;
    pointer-events: none;
    transition: transform 200ms;
  }

  &[aria-expanded='true'] {
    span {
      transform: rotate(45deg);
    }
  }

  &[hidden] {
    display: none;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}
