.o-icon-link {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 0.9lh;
    flex: 0 0 auto;
  }
}
