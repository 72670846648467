.c-hero {
  padding: 3rem 0 calc(2 * var(--vr));
  display: flex;
  align-items: center;
  position: relative;

  @media (min-width: $md) {
    padding: 6rem 0 var(--vr);
  }
}

.c-hero__desc {
  font-family: $heading-light;
  font-weight: 400;
  font-size: 1.3rem;
  max-width: 35rem;

  @media (min-width: $md) {
    font-size: 1.8rem;
  }
}

.c-hero__about {
  display: inline-block;
  font-size: 1.2rem;
  margin-top: 1em;
}

.c-hero__svg {
  width: 75%;
  max-width: 42rem;
  margin-bottom: var(--vr);
}
