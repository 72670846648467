.c-header {
  --bg-headerLink: var(--black);
  --headerBg: var(--white);
  display: flex;
  align-items: center;
  font-family: $heading-light;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  color: $black;
  background-color: var(--headerBg);

  @media (min-width: $md) {
    position: fixed;
    padding: 0 1rem 0 1.5rem;
    background-color: rgb(0 0 0 / 0);
    height: rem(70px);
  }

  .th-light & {
    --bg-headerLink: var(--black);
  }

  .th-dark & {
    --bg-headerLink: var(--white);
    --headerBg: #1d1d26;
  }

  @media (prefers-color-scheme: dark) {
    --bg-headerLink: var(--white);
    --headerBg: #1d1d26;

    .th-light & {
      --bg-headerLink: var(--black);
      --headerBg: var(--white);
    }
  }
}

.c-header__menu-btn {
  display: flex;
  position: relative;
  appearance: none;
  border: none;
  background-color: transparent;
  text-decoration-style: none;
  font-family: inherit;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  z-index: 2;
  width: 8rem;
  cursor: pointer;
  margin-right: -0.5rem;
  text-underline-offset: 0;
  transition:
    text-underline-offset 200ms,
    text-decoration-color 200ms;
  color: var(--bg-headerLink);

  @supports (text-decoration-color: transparent) {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    text-underline-offset: 0.4em;
  }

  &:focus-visible {
    outline: 1px dotted var(--primary);
  }

  &:focus:not(:focus-visible) {
    text-decoration-color: var(--primary);
  }

  @media (min-width: $md) {
    font-size: 1.1rem;
    margin-left: 2rem;
  }

  &[aria-expanded='true'] {
    --bg-headerLink: var(--white);
  }
}

.c-header__menu-icon {
  display: block;
  position: relative;
  width: rem(32px);
  height: rem(24px);
  margin-left: 1rem;

  > span,
  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 4px;
    top: calc(50% - 4px);
    left: 0;
    transition: opacity 95ms;
    transform-origin: center;
    background-color: var(--bg-headerLink, var(--black));
  }

  &::before,
  &::after {
    content: '';
    transition: transform 95ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::before {
    transform: translate3d(0, -10px, 0) rotate(0);
  }

  &::after {
    transform: translate3d(0, 10px, 0) rotate(0);
  }
}

.is-menu-open {
  .c-header__menu-icon {
    span {
      opacity: 0;
    }

    &::before {
      transform: translate3d(0, 0, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, 0, 0) rotate(-45deg);
    }
  }
}

.c-header__nav {
  margin-left: auto;
}

.c-header__right-content {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.c-header__social-links {
  display: flex;
}

.c-header__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  @media (min-width: $lg) {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
}

.c-header__link {
  padding: 0.5rem 1rem;
  color: var(--bg-headerLink);
  display: flex;
  gap: 0.25em;
  align-items: center;
  height: 100%;
  text-decoration: none;
  transition:
    color 200ms,
    text-underline-offset 200ms,
    text-decoration-color 200ms;

  @supports (text-decoration-color: transparent) {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0;
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    text-underline-offset: 0.4em;
    color: var(--primary);
    text-decoration-color: var(--primary);
  }

  @media (min-width: $md) {
    font-size: 1rem;
  }

  @media (min-width: $lg) {
    font-size: 1.1rem;
  }
}

.c-header__link--social {
  padding: 0;
  margin: 0 0.5rem;

  .c-header__item:first-child & {
    display: none;

    @media (min-width: $md) {
      display: flex;
    }
  }

  @media (min-width: $md) {
    margin: 0 1rem;
  }

  &:hover,
  &:focus {
    svg {
      color: var(--primary);
    }
  }
}

.c-header__title {
  font-family: 'Amiri', serif;
  font-size: 1.4rem;
  line-height: 1;
  display: flex;
  align-items: center;
  color: var(--bg-headerLink);
  text-decoration: none;
  margin-right: 1rem;

  &:hover,
  &:focus {
    color: $accent;
  }

  @media (min-width: $md) {
    font-size: 2rem;
    margin-bottom: 0;
    width: rem(280px);
    height: auto;
    transform: translate3d(0, -100%, 0);
    transition: transform 250ms;

    svg {
      height: 100%;
    }
  }
}

.c-header__logo {
  display: none;
  height: auto;
}

.js-menu {
  .c-header__nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 40rem;
    height: 100vh;
    padding: 5rem 1rem 2rem 1rem;
    overflow-y: scroll;
    background-color: $alt;
    transform: translate3d(100%, 0, 0);
    transition: transform 250ms;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25);

    @media (min-width: $md) {
      padding: 5rem 2rem 2rem 2rem;

      .c-header__link {
        font-size: 1.2rem;
      }
    }
  }

  .c-header__list {
    display: block;
    margin-left: 0;
  }
}

.is-visible {
  .c-header__nav {
    transform: translate3d(0, 0, 0);
  }
}

.c-header__logo-sm {
  display: none;
  width: rem(35px);
  height: rem(35px);
}

.c-header--bg {
  background-color: var(--headerBg);

  .c-header__title {
    visibility: visible;

    @media (min-width: $md) {
      transform: translate3d(0, 0, 0);
    }
  }

  .c-header__logo {
    @media (min-width: $md) {
      display: block;
    }
  }

  .c-header__logo-sm {
    display: block;

    @media (min-width: $md) {
      display: none;
    }
  }
}

.no-js {
  .c-header {
    position: static;
    background-color: var(--headerBg);

    @media (min-width: $lg) {
      position: fixed;
    }
  }

  .c-header__menu-btn {
    display: none;
  }

  .c-header__right-content {
    align-items: flex-start;

    @media (min-width: $lg) {
      align-items: center;
    }
  }

  .c-header__social-links {
    margin-right: 2rem;
  }

  .c-header__list {
    justify-content: flex-end;
  }
}

.c-header__list {
  --bg-headerLink: var(--white);
}
