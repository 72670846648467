.c-posts-grid {
  list-style: none;
  max-width: rem(800px);

  @media (min-width: $lg) {
    margin: 0 auto;
  }
}

.c-posts-grid__list {
  display: grid;
  gap: calc(var(--vr) * 2);
}

.c-posts-grid__header {
  border-bottom: 0.1rem solid var(--textColor);
  margin-bottom: calc(var(--vr) * 2);
  padding-bottom: var(--vr);
}

.c-posts-grid__post-heading {
  margin: 0;

  a {
    display: block;
    color: var(--textColor);
    text-decoration-color: var(--accentColor, var(--primary));
    text-decoration-color: var(--textColor);
    text-decoration-thickness: 0.08em;
    text-underline-offset: 0.2em;

    &:hover,
    &:focus {
      text-underline-offset: 0.3em;
      text-decoration-color: inherit;
    }

    svg {
      display: inline;
      width: 0.65em;
      height: auto;
      margin-left: 0.25rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover,
  &:focus {
    ~ .c-posts-grid__read-more {
      color: var(--primary);

      &::after {
        transform: translate3d(0.3em, 0, 0);
      }
    }
  }
}

.c-posts-grid__date {
  margin: rem(5px) 0;
  display: block;
  font-size: 0.8rem;
  font-family: $heading-light;

  @media (min-width: $md) {
    font-size: rem(15px);
    margin: rem(10px) 0 rem(20px);
  }
}

.c-posts-grid__excerpt,
.c-posts-grid__read-more {
  @media (min-width: $md) {
    font-size: rem(20px);
    margin-bottom: 0;
  }
}

.c-posts-grid__read-more {
  font-family: $heading;
  font-weight: 700;
  margin-top: var(--vr);
  transition: color 200ms;

  @media (min-width: $md) {
    font-size: rem(16px);
  }

  &::after {
    content: '➞';
    display: inline-block;
    transition: transform 150ms;
    margin-left: 0.2em;
  }
}

.c-post-listing {
  position: relative;

  .c-posts-grid__tag {
    background-color: var(--accentColor);
  }
}

.c-post-listing--note {
  --accentColor: royalblue;
}

.c-post-listing--quick-tip {
  --accentColor: turquoise;
}

.c-post-listing--external {
  --accentColor: lavender;
}

.c-post-listing--demo {
  --accentColor: orange;
}

.c-posts-grid__tag {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.c-posts-grid__heading {
  margin-bottom: var(--vr);
}
