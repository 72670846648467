.c-prev-next {
  font-family: $heading;
  margin-top: rem(20px);
  padding-top: rem(20px);
  border-top: 0.1rem solid var(--textColor);

  @media (min-width: 400px) {
    display: flex;
  }

  @media (min-width: $lg) {
    margin-top: 0;
  }
}

.c-prev-next__item {
  flex: 1 0 50%;
  transition: color 200ms;
  line-height: 1.5;

  &:first-child {
    margin-bottom: rem(20px);
  }

  @media (min-width: 400px) {
    &:first-child {
      padding-right: rem(20px);
      margin-bottom: 0;
    }

    &:nth-child(2) {
      padding-left: rem(20px);
      text-align: right;
    }
  }
}

.c-prev-next__link-desc {
  font-size: 0.9rem;
  display: block;
  text-decoration-style: none;
  margin-bottom: rem(10px);

  @media (min-width: $sm) {
    font-size: 1rem;
  }
}

.c-prev-next__link {
  color: var(--textColor);
  text-decoration-style: underline;
  text-decoration-color: var(--primary);
  text-decoration-thickness: 0.08em;
  text-underline-offset: 0.2em;

  &:hover,
  &:focus {
    color: var(--primary);
  }
}
