* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  background: var(--bg);
}

p {
  margin: 0;
}

ul {
  display: block;
  margin: 0;
  padding: 0;
}

ul[class] {
  list-style: none;
}

img,
video {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

figure {
  margin: 0;
}
