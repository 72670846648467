.c-webmentions {
  border-top: 0.1rem solid var(--textColor);
  padding-top: var(--vr);
  margin-top: calc(2 * var(--vr));
}

.c-webmentions__items {
  display: flex;
  gap: var(--vr);
  font-size: 1rem;
  font-family: $heading-light;
  margin-bottom: var(--vr);
}

.c-webmentions__item {
  display: flex;

  svg {
    width: 2em;
    margin-right: 0.25em;
  }
}

.c-webmentions__list {
  > * + * {
    margin-top: var(--vr);
  }
}

.c-webmentions__list-item {
  max-width: 50rem;
  font-size: max(1rem, 0.9em);

  blockquote {
    padding: calc(var(--vr) / 2) 1rem;
    background-color: var(--bgTint);
    margin-left: 0;
    border-radius: 0.3rem;
  }

  cite {
    font-style: normal;
    font-family: $heading-light;
    font-size: 1rem;
  }
}

.c-webmentions__header {
  margin-bottom: var(--vr);
}

.c-webmentions__heading {
  display: inline-block;
  margin-bottom: 0;
}

.c-webmentions__info-link {
  font-style: normal;
  font-family: $heading-light;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  color: var(--textGrey);
  text-decoration-color: var(--textGrey);

  svg {
    width: 1rem;
    margin-left: 0.4em;
    margin-top: 0.15em;
  }
}
