.c-dark-mode-toggle {
  transition: color 200ms;
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: var(--bg-headerLink);

  &:hover,
  &:focus-visible {
    color: var(--primary);
  }
}

.c-dark-mode-toggle__icon {
  width: 2.65rem;
  height: auto;
}

.c-dark-mode-toggle__icon--light {
  display: none;

  @media (prefers-color-scheme: dark) {
    display: block;
  }
}

.c-dark-mode-toggle__icon--dark {
  @media (prefers-color-scheme: dark) {
    display: none;
  }
}

.th-dark {
  .c-dark-mode-toggle__icon--light {
    display: block;
  }

  .c-dark-mode-toggle__icon--dark {
    display: none;
  }
}

.th-light {
  @media (prefers-color-scheme: dark) {
    .c-dark-mode-toggle__icon--light {
      display: none;
    }

    .c-dark-mode-toggle__icon--dark {
      display: block;
    }
  }
}

.no-js {
  .c-dark-mode-toggle {
    display: none;
  }
}
