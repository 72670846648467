.c-blogroll__list {
  padding: rem(20px) 0;
  font-family: $heading-light;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--vr);

  @media (min-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: calc(var(--vr) * 2);
    margin-top: calc(var(--vr) * 2);
  }
}

.c-blogroll__item {
  font-size: 0.9rem;
  padding: var(--vr);
  background-color: var(--bgTint);
  border-radius: 0.3rem;
  position: relative;

  @media (min-width: $md) {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.c-blogroll__heading {
  margin: 0 0 calc(var(--vr) / 4) 0;
}

.c-blogroll__link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
