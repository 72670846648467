:root {
  --black: #0e0f0f;
  --primary: deepPink;
  --headerBg: #1d1d26;
  --textColor: #0e0f0f;
  --textColorInverse: #fcfdff;
  --bg: #fbfbfb;
  --bgTint: #f0e5ea;
  --bgGrey: #e6e8e8;
  --white: #fcfdff;
  --borderStyle: none;
  --code: #f1d4e3;
  --textGrey: #737373;
  --vr: 1rem;
}

@media (width >= 50em) {
  :root {
    --vr: 1.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #18181a;
    --bgTint: #27272c;
    --textColor: #fcfdff;
    --bgGrey: #27272c;
    --textColorInverse: #0e0f0f;
    --borderStyle: 1px solid var(--primary);
    --code: var(--bgTint);
    --codeText: var(--primary);
    --textGrey: #b3b1b9;
  }
}

.th-dark {
  --bg: #18181a;
  --bgTint: #27272c;
  --textColor: #fcfdff;
  --bgGrey: #27272c;
  --textColorInverse: #0e0f0f;
  --borderStyle: 1px solid var(--primary);
  --code: var(--bgTint);
  --codeText: var(--primary);
  --textGrey: #b3b1b9;
}

@media (prefers-color-scheme: dark) {
  .th-light {
    --textColor: #0e0f0f;
    --textColorInverse: #fcfdff;
    --bg: #fbfbfb;
    --bgTint: #f0e5ea;
    --bgGrey: #e6e8e8;
    --borderStyle: 1px solid var(--primary);
    --code: #f1d4e3;
    --textGrey: #737373;
  }
}

* {
  box-sizing: border-box;
}

body {
  background: var(--bg);
  min-height: 100vh;
  margin: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  display: block;
}

ul[class] {
  list-style: none;
}

img, video {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

figure {
  margin: 0;
}

@font-face {
  font-family: Urbanist;
  src: url("../urbanist.7def60c8.woff2") format("woff2-variations");
  font-display: swap;
  font-weight: 100 800;
}

@font-face {
  font-family: Jost;
  src: url("../jost.15c78fb7.woff2") format("woff2-variations");
  font-display: swap;
  font-weight: 100 800;
}

@media (width >= 100em) {
  html {
    font-size: 110%;
  }
}

body {
  font-family: Jost, sans-serif;
}

@media (width >= 50em) {
  body {
    font-size: 1.2rem;
  }
}

@media (width >= 68em) {
  body {
    font-size: 1.4rem;
  }
}

h1, h2, h3, h4, h5, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5 {
  font-family: Urbanist, serif;
  font-weight: 700;
}

h1, .heading-1 {
  font-weight: 800;
}

p, ul, ol {
  line-height: 1.6;
}

a {
  color: var(--textColor);
  transition: color .2s, text-underline-offset .2s, text-decoration-color .2s;
}

@supports (text-decoration-color: transparent) {
  a {
    text-underline-offset: .2em;
    text-decoration: underline;
    text-decoration-thickness: .1em;
  }
}

a:hover, a:focus {
  text-underline-offset: .4em;
  color: var(--textColor);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primary);
  text-decoration-color: var(--primary);
  text-decoration-thickness: .1em;
  transition: color .2s, text-underline-offset .2s, text-decoration-color .2s;
}

pre {
  color: #e8eaed;
  background-color: #1d1d26;
  padding: 1rem;
  overflow: scroll;
}

blockquote {
  border-left: 8px solid var(--primary);
  padding-left: 1.25rem;
}

@media (width >= 50em) {
  blockquote {
    padding-left: 2.5rem;
  }
}

code {
  border-radius: .2em;
  padding: .125rem .25rem;
  font-size: 80%;
}

h1, .heading-1 {
  margin-top: 0;
  font-size: 1.8rem;
  line-height: 1.2;
}

@media (width >= 50em) {
  h1, .heading-1 {
    font-size: 3rem;
  }
}

@media (width >= 68em) {
  h1, .heading-1 {
    font-size: 3.6rem;
  }
}

h2, .heading-2 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (width >= 50em) {
  h2, .heading-2 {
    font-size: 2.8rem;
  }
}

h3, .heading-3 {
  font-size: 1.3rem;
  font-weight: 700;
}

@media (width >= 42em) {
  h3, .heading-3 {
    font-size: 1.4rem;
  }
}

@media (width >= 50em) {
  h3, .heading-3 {
    font-size: 2.3rem;
  }
}

h4, .heading-4 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (width >= 50em) {
  h4, .heading-4 {
    font-size: 1.6rem;
  }
}

li > h3 {
  margin-top: 1.5rem;
}

figcaption {
  margin-top: .5rem;
  font-size: 1rem;
}

small {
  font-size: .9rem;
}

p:empty {
  display: none;
}

.intro {
  margin-top: var(--vr);
  font-size: 1.1em;
  font-weight: 500;
}

@media (width >= 68em) {
  .intro {
    margin-bottom: var(--vr);
    margin-top: 0;
  }
}

.truncate-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

body {
  background-color: var(--bg);
  color: var(--textColor);
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (width >= 50em) {
  .container {
    padding: 0 2rem;
  }
}

@media (width >= 68em) {
  .container {
    padding: 0 4rem;
  }
}

@media (width >= 90em) {
  .container {
    max-width: 100rem;
  }
}

.container-sm {
  width: 100%;
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (width >= 50em) {
  .container-sm {
    padding: 0 2rem;
  }
}

@media (width >= 68em) {
  .container-sm {
    padding: 0 4rem;
  }
}

.page-content {
  flex-grow: 1;
  padding: 0 0 2rem;
}

@media (width >= 50em) {
  .page-content {
    padding: 0 0 6rem;
  }
}

.page-content--page {
  padding: 0 0 2rem;
}

@media (width >= 50em) {
  .page-content--page {
    padding: 3rem 0 6rem;
  }
}

@media (width >= 68em) {
  .page-content--page {
    padding: 8rem 0 6rem;
  }

  .post-grid {
    gap: calc(2 * var(--vr));
    grid-template-columns: [main-start] minmax(0, 58rem)[main-end] minmax(15.625rem, 1fr);
    display: grid;
  }
}

.post-grid__header {
  grid-column: 1 / -1;
}

@media (width >= 50em) {
  .post-grid__header {
    margin-top: 6rem;
  }
}

@media (width >= 68em) {
  .post-grid__main {
    grid-column: main;
  }

  .post-grid__prev-next {
    grid-column: 1 / -1;
  }

  .grid {
    gap: var(--vr) 2.5rem;
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  .grid__tags {
    grid-column: 1 / 4;
  }
}

.grid__featured-post {
  margin-top: 1rem;
}

@media (width >= 50em) {
  .grid__featured-post {
    margin-top: 2rem;
  }
}

@media (width >= 68em) {
  .grid__featured-post {
    margin-top: calc(var(--vr) * -6);
    grid-column: 4 / -1;
  }
}

.home-layout {
  padding-bottom: calc(var(--vr) * 2);
}

.home-layout__posts {
  gap: var(--vr);
  display: grid;
  position: relative;
}

@media (width >= 68em) {
  .home-layout__posts {
    padding-top: calc(var(--vr) * 2);
    grid-template-columns: minmax(20rem, 1fr) 2fr;
    gap: 3rem;
  }
}

.home-layout__tags-wrapper {
  max-width: 40rem;
}

@media (width >= 68em) {
  .home-layout__tags-wrapper {
    position: sticky;
    top: 5rem;
  }
}

.home-layout__tags-title {
  margin: 0 0 calc(var(--vr)) 0;
}

.home-layout__bg-logo {
  width: 20vw;
  max-width: 10rem;
  height: auto;
  position: absolute;
  top: -1%;
  left: -1%;
}

.page-content {
  padding-bottom: calc(var(--vr) * 2);
}

@media (width >= 50em) {
  .home-layout--no-hero {
    padding-top: 6rem;
  }
}

.home-layout__expander-button {
  appearance: none;
  font-family: Urbanist, serif;
  font-size: inherit;
  cursor: pointer;
  background: none;
  background-color: var(--bgGrey);
  color: var(--textColor);
  margin-bottom: var(--vr);
  border: none;
  border-radius: .3rem;
  padding: .75rem 1rem;
  display: flex;
}

.home-layout__expander-button span {
  pointer-events: none;
  margin-left: .5rem;
  transition: transform .2s;
}

.home-layout__expander-button[aria-expanded="true"] span {
  transform: rotate(45deg);
}

.home-layout__expander-button[hidden] {
  display: none;
}

@media (width >= 1024px) {
  .home-layout__expander-button {
    display: none;
  }
}

code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  color: #c3cee3;
  tab-size: 4;
  hyphens: none;
  background: #263238;
  font-family: Roboto Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

code[class*="language-"]::-moz-selection {
  background: #363636;
}

pre[class*="language-"]::-moz-selection {
  background: #363636;
}

code[class*="language-"] ::-moz-selection {
  background: #363636;
}

pre[class*="language-"] ::-moz-selection {
  background: #363636;
}

code[class*="language-"]::selection, pre[class*="language-"]::selection, code[class*="language-"] ::selection, pre[class*="language-"] ::selection {
  background: #363636;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .2em;
  padding: .1em;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1.25em 1em;
  position: relative;
  overflow: auto;
}

.language-css > code, .language-sass > code, .language-scss > code {
  color: #fd9170;
}

[class*="language-"] .namespace {
  opacity: .7;
}

.token.atrule {
  color: #c792ea;
}

.token.attr-name {
  color: #ffcb6b;
}

.token.attr-value, .token.attribute {
  color: #c3e88d;
}

.token.boolean {
  color: #c792ea;
}

.token.builtin {
  color: #ffcb6b;
}

.token.cdata, .token.char {
  color: #80cbc4;
}

.token.class {
  color: #ffcb6b;
}

.token.class-name, .token.color {
  color: #f2ff00;
}

.token.comment {
  color: #546e7a;
}

.token.constant {
  color: #c792ea;
}

.token.deleted {
  color: #f07178;
}

.token.doctype {
  color: #546e7a;
}

.token.entity {
  color: #f07178;
}

.token.function {
  color: #c792ea;
}

.token.hexcode {
  color: #f2ff00;
}

.token.id, .token.important {
  color: #c792ea;
  font-weight: bold;
}

.token.inserted {
  color: #80cbc4;
}

.token.keyword {
  color: #c792ea;
  font-style: italic;
}

.token.number {
  color: #fd9170;
}

.token.operator {
  color: #89ddff;
}

.token.prolog {
  color: #546e7a;
}

.token.property {
  color: #80cbc4;
}

.token.pseudo-class, .token.pseudo-element {
  color: #c3e88d;
}

.token.punctuation {
  color: #89ddff;
}

.token.regex {
  color: #f2ff00;
}

.token.selector {
  color: #f07178;
}

.token.string {
  color: #c3e88d;
}

.token.symbol {
  color: #c792ea;
}

.token.tag, .token.unit {
  color: #f07178;
}

.token.url {
  color: #fd9170;
}

.token.variable {
  color: #f07178;
}

:root {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  --textDecorationColor: var(--textColor);
}

@media (prefers-color-scheme: dark) {
  :root {
    --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
    --textDecorationColor: var(--primary);
  }
}

.th-dark {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  --textDecorationColor: var(--primary);
}

@media (prefers-color-scheme: dark) {
  .th-light {
    --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
    --textDecorationColor: var(--textColor);
  }
}

.o-richtext {
  padding-top: calc(var(--vr) * 2);
}

.o-richtext > * + * {
  margin-top: var(--vr);
}

.o-richtext a {
  -webkit-text-decoration-color: var(--textDecorationColor);
  text-decoration-color: var(--textDecorationColor);
}

.o-richtext a[href^="http"] {
  padding-right: .7em;
}

.o-richtext a[href^="http"]:after {
  content: "";
  background-size: 100%;
  background-image: var(--externalLinkImage);
  width: .7em;
  height: .7em;
  margin-left: .15em;
  display: inline-block;
  position: absolute;
  transform: translateY(.2em);
}

.o-richtext h2 + h3 {
  margin-top: var(--vr);
}

.o-richtext img {
  background: var(--bgTint);
}

.o-richtext pre {
  margin: var(--vr) 0;
  max-width: 60rem;
  padding: 1rem;
  font-size: .8em;
  display: block;
}

.o-richtext pre * {
  margin-top: 0;
}

.o-richtext code, .o-richtext kbd {
  background: var(--code);
  color: inherit;
  font-size: inherit;
  border-radius: .25rem;
}

@media (width >= 50em) {
  .o-richtext code, .o-richtext kbd {
    font-size: .75em;
  }
}

.o-richtext kbd {
  background: var(--bgTint);
  padding: .1em .6em;
}

.o-richtext pre code {
  color: #fcfdff;
  background: none;
  border-radius: 0;
  padding: 0;
  font-size: .86em;
  font-weight: 400;
}

.o-richtext figure {
  margin-block: var(--vr);
}

.o-richtext figure img {
  box-shadow: 0 0 1.5rem #0000002e;
}

.o-richtext :is(figure, aside) + * {
  margin-top: calc(var(--vr) * 2);
}

.o-richtext figcaption {
  margin: calc(var(--vr) / 2) 0 0 0;
}

.o-richtext li + li {
  margin-top: calc(var(--vr) / 2);
}

.o-richtext h2, .o-richtext h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.o-richtext > * + :is(h2, h3, aside, figure, p:has(:first-child.o-hotlink)) {
  margin-top: calc(var(--vr) * 2);
}

.o-richtext h3 + * {
  margin-top: var(--vr);
}

.o-richtext iframe {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.o-richtext blockquote {
  border-left: 4px solid var(--primary);
  background-color: var(--bgTint);
  padding: var(--vr);
}

.o-richtext aside {
  padding: var(--vr);
  background-color: var(--bgTint);
  border: var(--borderStyle);
  border-radius: .3rem;
  font-family: Urbanist, serif;
  font-size: .9rem;
  line-height: 1.6;
  position: relative;
}

.o-richtext aside > * + * {
  margin: .8rem 0 0;
}

.o-richtext aside a:hover, .o-richtext aside a:focus {
  cursor: pointer;
  color: var(--textColor);
}

.o-richtext aside:before {
  background-color: var(--primary);
  color: #0e0f0f;
  content: "<aside>";
  border-radius: .1rem;
  padding: 0 .5rem;
  font-size: .9em;
  position: absolute;
  top: -1rem;
  left: 1rem;
}

@media (width >= 50em) {
  .o-richtext aside {
    font-size: 1.125rem;
  }
}

.o-richtext ul {
  padding-left: 1.25rem;
}

.o-richtext ul li {
  padding-left: 1rem;
  position: relative;
}

@media (width >= 50em) {
  .o-richtext ul li {
    padding-left: 1.5rem;
  }

  .o-richtext ul {
    padding-left: 2.5rem;
  }
}

@media (width >= 68em) {
  .o-richtext {
    padding-top: 0;
  }
}

.o-richtext .o-hotlink[href^="http"] {
  padding: .25em 1em;
}

.o-richtext .o-hotlink[href^="http"]:after {
  position: relative;
}

.o-hotlink {
  background-color: var(--primary);
  border: .1rem solid var(--primary);
  color: var(--white);
  border-radius: .5rem;
  padding: .25em 1em;
  text-decoration: none;
  transition: color .15s, background-color .15s;
  display: inline-flex;
}

.o-hotlink[href^="http"]:after {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  position: relative;
}

.o-hotlink:hover, .o-hotlink:focus-visible {
  background-color: var(--bg);
  color: var(--primary);
  text-decoration: none;
}

.o-hotlink:hover[href^="http"]:after, .o-hotlink:focus-visible[href^="http"]:after {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='deeppink' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='deeppink' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
}

.o-icon-link {
  align-items: center;
  gap: .25rem;
  display: flex;
}

.o-icon-link svg {
  flex: none;
  width: .9lh;
}

@media (width >= 50em) {
  .c-all-tags__list {
    columns: 20rem;
    column-gap: 2rem;
  }
}

.c-all-tags__link {
  color: var(--textColor);
}

.c-all-tags__link:hover, .c-all-tags__link:focus {
  color: var(--primary);
}

.c-all-tags__post-count {
  color: var(--primary);
  font-family: Urbanist, serif;
  font-size: .9rem;
}

@media (width >= 50em) {
  .c-all-tags__post-count {
    font-size: 1rem;
  }
}

.c-alt-header {
  --py: clamp(3rem, 10vw, 20rem);
  background: linear-gradient(-45deg, var(--color2, deeppink), var(--color1, darkorchid));
  padding: var(--py) 0 var(--vr) 0;
  color: var(--headerTextColor, var(--white));
  background-attachment: fixed;
}

.c-alt-header .c-tag-links__tag a {
  color: var(--white);
  background-color: var(--headerBg);
}

.c-alt-header .c-tag-links__tag--note a, .c-alt-header .c-tag-links__tag--quick-tip a {
  background-color: var(--note, royalblue);
  color: var(--white);
}

.c-alt-header .c-tag-links__tag--quick-tip a {
  background-color: var(--note, turquoise);
  color: var(--black);
}

.c-alt-header .c-tag-links__tag--demo a {
  background-color: var(--note, orange);
  color: var(--black);
}

@media (width >= 68em) {
  .c-alt-header {
    margin-bottom: calc(var(--vr) * 2);
  }
}

.c-author-info {
  padding: var(--vr);
  background-color: var(--bgTint);
  margin-top: calc(var(--vr) * 2);
}

@media (width >= 50em) {
  .c-author-info p {
    font-size: 1.2rem;
  }
}

.c-author-info p:last-child {
  margin-bottom: 0;
}

.c-author-info p + p {
  margin-top: var(--vr);
}

@media (width >= 50em) {
  .c-author-info {
    padding: calc(var(--vr) * 2);
    display: flex;
  }
}

.c-author-info__image {
  margin: 0 auto var(--vr) auto;
  filter: grayscale();
  border-radius: 50%;
  max-width: 6.25rem;
  overflow: hidden;
}

@media (width >= 50em) {
  .c-author-info__image {
    margin: 0 var(--vr) var(--vr) auto;
    align-self: flex-start;
    max-width: 9.375rem;
  }
}

.c-author-info__heading {
  margin: 0 0 var(--vr) 0;
}

.c-blogroll__list {
  gap: var(--vr);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 1.25rem 0;
  font-family: Urbanist, serif;
  display: grid;
}

@media (width >= 42em) {
  .c-blogroll__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 68em) {
  .c-blogroll__list {
    gap: calc(var(--vr) * 2);
    margin-top: calc(var(--vr) * 2);
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-blogroll__item {
  padding: var(--vr);
  background-color: var(--bgTint);
  border-radius: .3rem;
  font-size: .9rem;
  position: relative;
}

@media (width >= 50em) {
  .c-blogroll__item {
    margin-bottom: 0;
    font-size: 1rem;
  }
}

.c-blogroll__heading {
  margin: 0 0 calc(var(--vr) / 4) 0;
}

.c-blogroll__link:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-categories__item {
  font-family: Urbanist, serif;
}

.c-categories__item a {
  color: var(--text-color);
  align-items: baseline;
  padding: .5rem 0;
  text-decoration-color: #0000;
  display: inline-flex;
}

.c-categories__item a:after {
  content: "➞";
  opacity: 0;
  margin-left: .2rem;
  text-decoration-color: #0000;
  transition: opacity .15s, transform .15s;
  transform: translate3d(0, 0, 0);
}

.c-categories__item a span:first-child {
  text-decoration: underline;
}

.c-categories__item a:hover, .c-categories__item a:focus {
  text-decoration-color: #0000;
}

.c-categories__item a:hover:after, .c-categories__item a:focus:after {
  opacity: 1;
  transform: translate3d(.5rem, 0, 0);
}

@media (hover: hover) and (pointer: fine) {
  .c-categories__item a {
    padding: .1rem 0;
  }
}

.c-categories__title {
  margin-bottom: calc(var(--vr) / 2);
}

.c-categories__post-count {
  color: var(--textGrey);
  margin-left: .3em;
  font-family: Urbanist, serif;
  font-size: max(.6em, .9rem);
}

.c-dark-mode-toggle {
  cursor: pointer;
  color: var(--bg-headerLink);
  background: none;
  border: none;
  padding: .25rem;
  transition: color .2s;
}

.c-dark-mode-toggle:hover, .c-dark-mode-toggle:focus-visible {
  color: var(--primary);
}

.c-dark-mode-toggle__icon {
  width: 2.65rem;
  height: auto;
}

.c-dark-mode-toggle__icon--light {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .c-dark-mode-toggle__icon--light {
    display: block;
  }

  .c-dark-mode-toggle__icon--dark {
    display: none;
  }
}

.th-dark .c-dark-mode-toggle__icon--light {
  display: block;
}

.th-dark .c-dark-mode-toggle__icon--dark {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .th-light .c-dark-mode-toggle__icon--light {
    display: none;
  }

  .th-light .c-dark-mode-toggle__icon--dark {
    display: block;
  }
}

.no-js .c-dark-mode-toggle {
  display: none;
}

.c-header {
  --bg-headerLink: var(--black);
  --headerBg: var(--white);
  z-index: 3;
  color: #0e0f0f;
  background-color: var(--headerBg);
  align-items: center;
  width: 100%;
  padding: .5rem .5rem .5rem 1rem;
  font-family: Urbanist, serif;
  display: flex;
  top: 0;
  left: 0;
}

@media (width >= 50em) {
  .c-header {
    background-color: #0000;
    height: 4.375rem;
    padding: 0 1rem 0 1.5rem;
    position: fixed;
  }
}

.th-light .c-header {
  --bg-headerLink: var(--black);
}

.th-dark .c-header {
  --bg-headerLink: var(--white);
  --headerBg: #1d1d26;
}

@media (prefers-color-scheme: dark) {
  .c-header {
    --bg-headerLink: var(--white);
    --headerBg: #1d1d26;
  }

  .th-light .c-header {
    --bg-headerLink: var(--black);
    --headerBg: var(--white);
  }
}

.c-header__menu-btn {
  appearance: none;
  -webkit-text-decoration-style: none;
  text-decoration-style: none;
  z-index: 2;
  cursor: pointer;
  text-underline-offset: 0;
  color: var(--bg-headerLink);
  background-color: #0000;
  border: none;
  width: 8rem;
  margin-right: -.5rem;
  padding: .5rem 1rem;
  font-family: inherit;
  font-size: 1rem;
  transition: text-underline-offset .2s, text-decoration-color .2s;
  display: flex;
  position: relative;
}

@supports (text-decoration-color: transparent) {
  .c-header__menu-btn {
    text-underline-offset: 0;
    -webkit-text-decoration: underline #0000;
    text-decoration: underline #0000;
  }
}

.c-header__menu-btn:hover, .c-header__menu-btn:focus {
  text-underline-offset: .4em;
  text-decoration: underline;
}

.c-header__menu-btn:focus-visible {
  outline: 1px dotted var(--primary);
}

.c-header__menu-btn:focus:not(:focus-visible) {
  -webkit-text-decoration-color: var(--primary);
  text-decoration-color: var(--primary);
}

@media (width >= 50em) {
  .c-header__menu-btn {
    margin-left: 2rem;
    font-size: 1.1rem;
  }
}

.c-header__menu-btn[aria-expanded="true"] {
  --bg-headerLink: var(--white);
}

.c-header__menu-icon {
  width: 2rem;
  height: 1.5rem;
  margin-left: 1rem;
  display: block;
  position: relative;
}

.c-header__menu-icon > span, .c-header__menu-icon:before, .c-header__menu-icon:after {
  transform-origin: center;
  background-color: var(--bg-headerLink, var(--black));
  width: 100%;
  height: 4px;
  transition: opacity 95ms;
  position: absolute;
  top: calc(50% - 4px);
  left: 0;
}

.c-header__menu-icon:before, .c-header__menu-icon:after {
  content: "";
  transition: transform 95ms cubic-bezier(.55, .055, .675, .19);
}

.c-header__menu-icon:before {
  transform: translate3d(0, -10px, 0)rotate(0);
}

.c-header__menu-icon:after {
  transform: translate3d(0, 10px, 0)rotate(0);
}

.is-menu-open .c-header__menu-icon span {
  opacity: 0;
}

.is-menu-open .c-header__menu-icon:before {
  transform: translate3d(0, 0, 0)rotate(45deg);
}

.is-menu-open .c-header__menu-icon:after {
  transform: translate3d(0, 0, 0)rotate(-45deg);
}

.c-header__nav {
  margin-left: auto;
}

.c-header__right-content {
  align-items: center;
  margin-left: auto;
  display: flex;
}

.c-header__social-links {
  display: flex;
}

.c-header__list {
  flex-wrap: wrap;
  margin-left: -1rem;
  display: flex;
}

@media (width >= 68em) {
  .c-header__list {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.c-header__link {
  color: var(--bg-headerLink);
  align-items: center;
  gap: .25em;
  height: 100%;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .2s, text-underline-offset .2s, text-decoration-color .2s;
  display: flex;
}

@supports (text-decoration-color: transparent) {
  .c-header__link {
    text-underline-offset: 0;
    -webkit-text-decoration: underline #0000;
    text-decoration: underline #0000;
  }
}

.c-header__link svg {
  width: 1.75rem;
  height: 1.75rem;
}

.c-header__link:hover, .c-header__link:focus {
  text-underline-offset: .4em;
  color: var(--primary);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primary);
  text-decoration-color: var(--primary);
}

@media (width >= 50em) {
  .c-header__link {
    font-size: 1rem;
  }
}

@media (width >= 68em) {
  .c-header__link {
    font-size: 1.1rem;
  }
}

.c-header__link--social {
  margin: 0 .5rem;
  padding: 0;
}

.c-header__item:first-child .c-header__link--social {
  display: none;
}

@media (width >= 50em) {
  .c-header__item:first-child .c-header__link--social {
    display: flex;
  }

  .c-header__link--social {
    margin: 0 1rem;
  }
}

.c-header__link--social:hover svg, .c-header__link--social:focus svg {
  color: var(--primary);
}

.c-header__title {
  color: var(--bg-headerLink);
  align-items: center;
  margin-right: 1rem;
  font-family: Amiri, serif;
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

.c-header__title:hover, .c-header__title:focus {
  color: #ff1493;
}

@media (width >= 50em) {
  .c-header__title {
    width: 17.5rem;
    height: auto;
    margin-bottom: 0;
    font-size: 2rem;
    transition: transform .25s;
    transform: translate3d(0, -100%, 0);
  }

  .c-header__title svg {
    height: 100%;
  }
}

.c-header__logo {
  height: auto;
  display: none;
}

.js-menu .c-header__nav {
  background-color: #121218;
  width: 100%;
  max-width: 40rem;
  height: 100vh;
  padding: 5rem 1rem 2rem;
  transition: transform .25s;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 1.25rem #00000040;
}

@media (width >= 50em) {
  .js-menu .c-header__nav {
    padding: 5rem 2rem 2rem;
  }

  .js-menu .c-header__nav .c-header__link {
    font-size: 1.2rem;
  }
}

.js-menu .c-header__list {
  margin-left: 0;
  display: block;
}

.is-visible .c-header__nav {
  transform: translate3d(0, 0, 0);
}

.c-header__logo-sm {
  width: 2.1875rem;
  height: 2.1875rem;
  display: none;
}

.c-header--bg {
  background-color: var(--headerBg);
}

.c-header--bg .c-header__title {
  visibility: visible;
}

@media (width >= 50em) {
  .c-header--bg .c-header__title {
    transform: translate3d(0, 0, 0);
  }

  .c-header--bg .c-header__logo {
    display: block;
  }
}

.c-header--bg .c-header__logo-sm {
  display: block;
}

@media (width >= 50em) {
  .c-header--bg .c-header__logo-sm {
    display: none;
  }
}

.no-js .c-header {
  background-color: var(--headerBg);
  position: static;
}

@media (width >= 68em) {
  .no-js .c-header {
    position: fixed;
  }
}

.no-js .c-header__menu-btn {
  display: none;
}

.no-js .c-header__right-content {
  align-items: flex-start;
}

@media (width >= 68em) {
  .no-js .c-header__right-content {
    align-items: center;
  }
}

.no-js .c-header__social-links {
  margin-right: 2rem;
}

.no-js .c-header__list {
  justify-content: flex-end;
}

.c-header__list {
  --bg-headerLink: var(--white);
}

.c-footer {
  background-color: var(--bgGrey);
  flex-wrap: wrap;
  align-items: baseline;
  gap: 1rem;
  padding: 1rem;
  font-family: Urbanist, serif;
  font-size: .9rem;
  display: flex;
}

@media (width >= 50em) {
  .c-footer {
    padding: 1rem 2rem;
  }

  .c-footer > :nth-child(2) {
    margin-left: auto;
  }
}

.c-footer__links {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.c-hero {
  padding: 3rem 0 calc(2 * var(--vr));
  align-items: center;
  display: flex;
  position: relative;
}

@media (width >= 50em) {
  .c-hero {
    padding: 6rem 0 var(--vr);
  }
}

.c-hero__desc {
  max-width: 35rem;
  font-family: Urbanist, serif;
  font-size: 1.3rem;
  font-weight: 400;
}

@media (width >= 50em) {
  .c-hero__desc {
    font-size: 1.8rem;
  }
}

.c-hero__about {
  margin-top: 1em;
  font-size: 1.2rem;
  display: inline-block;
}

.c-hero__svg {
  margin-bottom: var(--vr);
  width: 75%;
  max-width: 42rem;
}

.c-page-header {
  padding-bottom: var(--vr);
  margin-bottom: var(--vr);
  border-bottom: .1rem solid var(--textColor);
}

.c-page-header__post-count {
  margin-bottom: .5rem;
  font-family: Urbanist, serif;
  font-size: 1rem;
  display: block;
}

.c-posts-grid {
  max-width: 50rem;
  list-style: none;
}

@media (width >= 68em) {
  .c-posts-grid {
    margin: 0 auto;
  }
}

.c-posts-grid__list {
  gap: calc(var(--vr) * 2);
  display: grid;
}

.c-posts-grid__header {
  border-bottom: .1rem solid var(--textColor);
  margin-bottom: calc(var(--vr) * 2);
  padding-bottom: var(--vr);
}

.c-posts-grid__post-heading {
  margin: 0;
}

.c-posts-grid__post-heading a {
  color: var(--textColor);
  -webkit-text-decoration-color: var(--accentColor, var(--primary));
  text-decoration-color: var(--accentColor, var(--primary));
  -webkit-text-decoration-color: var(--textColor);
  text-decoration-color: var(--textColor);
  text-underline-offset: .2em;
  text-decoration-thickness: .08em;
  display: block;
}

.c-posts-grid__post-heading a:hover, .c-posts-grid__post-heading a:focus {
  text-underline-offset: .3em;
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.c-posts-grid__post-heading a svg {
  width: .65em;
  height: auto;
  margin-left: .25rem;
  display: inline;
}

.c-posts-grid__post-heading a:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-posts-grid__post-heading:hover ~ .c-posts-grid__read-more, .c-posts-grid__post-heading:focus ~ .c-posts-grid__read-more {
  color: var(--primary);
}

.c-posts-grid__post-heading:hover ~ .c-posts-grid__read-more:after, .c-posts-grid__post-heading:focus ~ .c-posts-grid__read-more:after {
  transform: translate3d(.3em, 0, 0);
}

.c-posts-grid__date {
  margin: .3125rem 0;
  font-family: Urbanist, serif;
  font-size: .8rem;
  display: block;
}

@media (width >= 50em) {
  .c-posts-grid__date {
    margin: .625rem 0 1.25rem;
    font-size: .9375rem;
  }

  .c-posts-grid__excerpt, .c-posts-grid__read-more {
    margin-bottom: 0;
    font-size: 1.25rem;
  }
}

.c-posts-grid__read-more {
  margin-top: var(--vr);
  font-family: Urbanist, serif;
  font-weight: 700;
  transition: color .2s;
}

@media (width >= 50em) {
  .c-posts-grid__read-more {
    font-size: 1rem;
  }
}

.c-posts-grid__read-more:after {
  content: "➞";
  margin-left: .2em;
  transition: transform .15s;
  display: inline-block;
}

.c-post-listing {
  position: relative;
}

.c-post-listing .c-posts-grid__tag {
  background-color: var(--accentColor);
}

.c-post-listing--note {
  --accentColor: royalblue;
}

.c-post-listing--quick-tip {
  --accentColor: turquoise;
}

.c-post-listing--external {
  --accentColor: lavender;
}

.c-post-listing--demo {
  --accentColor: orange;
}

.c-posts-grid__tag {
  margin-bottom: .5rem;
  display: inline-block;
}

.c-posts-grid__heading {
  margin-bottom: var(--vr);
}

.c-post-grid-pagination__items {
  border-top: .1rem solid var(--textColor);
  margin: 1.25rem 0;
  padding: 1.25rem 0 0;
  font-family: Urbanist, serif;
  list-style: none;
  display: flex;
}

@media (width >= 68em) {
  .c-post-grid-pagination__items {
    margin-top: 3.75rem;
  }
}

.c-post-grid-pagination__items a {
  color: inherit;
  font-weight: 400;
  text-decoration: none;
}

.c-post-grid-pagination__items a:hover, .c-post-grid-pagination__items a:focus {
  color: var(--primary);
}

.c-post-grid-pagination__item--next {
  margin-left: auto;
}

.c-post-header {
  padding-top: var(--vr);
  margin-bottom: calc(var(--vr) * 2);
}

.c-post-header > * + * {
  margin: .3125rem 0 0;
}

@media (width >= 50em) {
  .c-post-header > * + * {
    margin: .625rem 0 0;
  }

  .c-post-header {
    margin-bottom: var(--vr);
  }
}

@media (width >= 68em) {
  @supports (display: grid) {
    .c-post-header {
      margin-bottom: 0;
    }
  }
}

.c-post-header__date, .c-post-header__source {
  font-family: Urbanist, serif;
  font-size: .9rem;
  display: block;
}

@media (width >= 50em) {
  .c-post-header__date, .c-post-header__source {
    font-size: 1rem;
  }
}

.c-post-header__series {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  display: block;
}

@media (width >= 50em) {
  .c-post-header__series {
    font-size: 1.5rem;
  }
}

.c-post-header__title {
  letter-spacing: -.01em;
  max-width: 25ch;
  margin-bottom: 0;
}

@media (width >= 68em) {
  .c-post-header__title {
    margin-bottom: var(--vr);
  }
}

.c-post-header {
  padding-bottom: calc(var(--vr) * 2);
  border-bottom: .1rem solid var(--textColor);
  margin-bottom: 0;
  position: relative;
}

@media (width >= 68em) {
  .page-content--about .c-post-header__title {
    margin-bottom: 0;
  }
}

.c-prev-next {
  border-top: .1rem solid var(--textColor);
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  font-family: Urbanist, serif;
}

@media (width >= 400px) {
  .c-prev-next {
    display: flex;
  }
}

@media (width >= 68em) {
  .c-prev-next {
    margin-top: 0;
  }
}

.c-prev-next__item {
  flex: 1 0 50%;
  line-height: 1.5;
  transition: color .2s;
}

.c-prev-next__item:first-child {
  margin-bottom: 1.25rem;
}

@media (width >= 400px) {
  .c-prev-next__item:first-child {
    margin-bottom: 0;
    padding-right: 1.25rem;
  }

  .c-prev-next__item:nth-child(2) {
    text-align: right;
    padding-left: 1.25rem;
  }
}

.c-prev-next__link-desc {
  -webkit-text-decoration-style: none;
  text-decoration-style: none;
  margin-bottom: .625rem;
  font-size: .9rem;
  display: block;
}

@media (width >= 42em) {
  .c-prev-next__link-desc {
    font-size: 1rem;
  }
}

.c-prev-next__link {
  color: var(--textColor);
  -webkit-text-decoration-style: underline;
  text-decoration-style: underline;
  -webkit-text-decoration-color: var(--primary);
  text-decoration-color: var(--primary);
  text-underline-offset: .2em;
  text-decoration-thickness: .08em;
}

.c-prev-next__link:hover, .c-prev-next__link:focus {
  color: var(--primary);
}

.sidebar {
  margin-top: var(--vr);
  padding-top: var(--vr);
  border-top: 1px solid;
}

@media (width >= 68em) {
  .sidebar {
    border: none;
    margin-top: 0;
    padding-top: 0;
    position: sticky;
    top: 5rem;
  }
}

.sidebar__heading {
  margin-top: 0;
  font-family: Urbanist, serif;
  font-size: .8em;
  font-weight: normal;
}

.sidebar__list {
  gap: 1em;
  display: grid;
  position: sticky;
  top: 0;
}

.sidebar__link {
  font-family: Urbanist, serif;
  font-weight: 700;
  line-height: 1.25;
}

.c-tag-links {
  flex-wrap: wrap;
  gap: .5em;
  display: flex;
}

.c-tag-links__tag {
  margin-bottom: .2rem;
  font-family: Urbanist, serif;
  font-size: .9rem;
}

.c-tag-links__tag a {
  display: block;
}

@media (width >= 50em) {
  .c-tag-links__tag {
    font-size: 1rem;
  }
}

.c-posts-grid__tag, .c-tag-links__tag a {
  background-color: var(--primary);
  color: var(--black);
  border-radius: .2rem;
  padding: 0 .375rem;
  font-family: Urbanist, serif;
  font-size: .9rem;
  font-weight: 400;
  text-decoration: none;
  transition: background-color .2s, color .2s, transform .2s;
}

@media (width >= 50em) {
  .c-posts-grid__tag, .c-tag-links__tag a {
    font-size: 1rem;
  }
}

.c-tag-links__tag a {
  background-color: var(--primary);
  color: var(--textColorInverse);
}

.c-tag-links__tag a:hover, .c-tag-links__tag a:focus {
  color: var(--primary);
  background-color: var(--textColor);
  transform: scale(1.1);
}

@media (width <= 50em) {
  .c-tag-links--home {
    text-align: center;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
  }

  .c-tag-links--home a {
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: .5rem;
    line-height: 1.2;
    display: flex;
  }

  .c-tag-links--home .c-tag-links__tag:last-child a {
    border: 1px solid;
  }

  .c-tag-links--home .c-tag-links__tag:nth-child(n+7):not(:last-child) {
    display: none;
  }
}

.c-tag-links--home .c-tag-links__tag:last-child a {
  color: var(--textColor);
  background: none;
  text-decoration: underline;
}

.c-tag-links--home .c-tag-links__tag:last-child a:hover, .c-tag-links--home .c-tag-links__tag:last-child a:focus {
  color: var(--textColor);
}

.c-posts-grid__tag--note, .c-tag-links__tag--note a, .c-tag-links__tag--quick-tip a, .c-posts-grid__tag--quick-tip, .c-posts-grid__tag--demo, .c-tag-links__tag--demo a, .c-posts-grid__tag--source {
  background-color: var(--note, royalblue);
  color: var(--white);
  font-weight: 700;
}

.c-posts-grid__tag--quick-tip, .c-tag-links__tag--quick-tip a {
  background-color: var(--note, turquoise);
  color: var(--black);
}

.c-posts-grid__tag--demo, .c-tag-links__tag--demo a {
  --accentColor: orange;
  background-color: var(--accentColor);
  color: var(--black);
}

.c-posts-grid__tag--source {
  color: var(--black);
}

.c-webmentions {
  border-top: .1rem solid var(--textColor);
  padding-top: var(--vr);
  margin-top: calc(2 * var(--vr));
}

.c-webmentions__items {
  gap: var(--vr);
  margin-bottom: var(--vr);
  font-family: Urbanist, serif;
  font-size: 1rem;
  display: flex;
}

.c-webmentions__item {
  display: flex;
}

.c-webmentions__item svg {
  width: 2em;
  margin-right: .25em;
}

.c-webmentions__list > * + * {
  margin-top: var(--vr);
}

.c-webmentions__list-item {
  max-width: 50rem;
  font-size: max(1rem, .9em);
}

.c-webmentions__list-item blockquote {
  padding: calc(var(--vr) / 2) 1rem;
  background-color: var(--bgTint);
  border-radius: .3rem;
  margin-left: 0;
}

.c-webmentions__list-item cite {
  font-family: Urbanist, serif;
  font-size: 1rem;
  font-style: normal;
}

.c-webmentions__header {
  margin-bottom: var(--vr);
}

.c-webmentions__heading {
  margin-bottom: 0;
  display: inline-block;
}

.c-webmentions__info-link {
  color: var(--textGrey);
  -webkit-text-decoration-color: var(--textGrey);
  text-decoration-color: var(--textGrey);
  align-items: center;
  font-family: Urbanist, serif;
  font-size: 1rem;
  font-style: normal;
  display: inline-flex;
}

.c-webmentions__info-link svg {
  width: 1rem;
  margin-top: .15em;
  margin-left: .4em;
}

.hidden {
  display: none;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=styles.css.map */
