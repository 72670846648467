.c-categories__item {
  font-family: $heading-light;

  a {
    color: var(--text-color);
    text-decoration-color: transparent;
    display: inline-flex;
    align-items: baseline;
    padding: 0.5rem 0;

    &::after {
      content: '➞';
      margin-left: 0.2rem;
      text-decoration-color: transparent;
      opacity: 0;
      transform: translate3d(0, 0, 0);
      transition: opacity 150ms, transform 150ms;
    }

    span:first-child {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      text-decoration-color: transparent;

      &::after {
        opacity: 1;
        transform: translate3d(0.5rem, 0, 0);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      padding: 0.1rem 0;
    }
  }
}

.c-categories__title {
  margin-bottom: calc(var(--vr) / 2);
}

.c-categories__post-count {
  font-family: $heading-light;
  font-size: max(0.6em, 0.9rem);
  margin-left: 0.3em;
  color: var(--textGrey);
}
