.c-footer {
  font-family: $heading-light;
  font-size: 0.9rem;
  padding: 1rem;
  background-color: var(--bgGrey);
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 1rem;

  @media (min-width: $md) {
    padding: 1rem 2rem;

    & > :nth-child(2) {
      margin-left: auto;
    }
  }
}

.c-footer__links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
