// Breakpoints
$sm: 42em;
$md: 50em;
$lg: 68em;
$xl: 80em;

// Layout
$wrapper-sm: rem(1024px);
$wrapper: rem(1400px);
$wrapper-large: rem(1600px);

/* Colors */
$white: #f7f7f7;
$lightGrey: #cdced1;

$accent: deeppink;
$bg-dark: #1d1d26;
$alt: darken($bg-dark, 5%);
$primary: deepPink;
$black: #0e0f0f;
$bg-light: #e1f1f2;
$white: #fcfdff;
$light-grey: #e8eaed;

:root {
  --black: #0e0f0f;
  --primary: deepPink;
  --headerBg: #1d1d26;
  --textColor: #0e0f0f;
  --textColorInverse: #fcfdff;
  --bg: #fbfbfb;
  --bgTint: #f0e5ea;
  --bgGrey: #e6e8e8;
  --white: #fcfdff;
  --borderStyle: none;
  --code: rgb(241, 212, 227);
  --textGrey: #737373;

  --vr: 1rem;

  @media (min-width: $md) {
    --vr: 1.5rem;
  }

  @media (prefers-color-scheme: dark) {
    --bg: #18181a;
    --bgTint: #27272c;
    --textColor: #fcfdff;
    --bgGrey: #27272c;
    --textColorInverse: #0e0f0f;
    --borderStyle: 1px solid var(--primary);
    --code: var(--bgTint);
    --codeText: var(--primary);
    --textGrey: #b3b1b9;
  }
}

.th-dark {
  --bg: #18181a;
  --bgTint: #27272c;
  --textColor: #fcfdff;
  --bgGrey: #27272c;
  --textColorInverse: #0e0f0f;
  --borderStyle: 1px solid var(--primary);
  --code: var(--bgTint);
  --codeText: var(--primary);
  --textGrey: #b3b1b9;
}

.th-light {
  @media (prefers-color-scheme: dark) {
    --textColor: #0e0f0f;
    --textColorInverse: #fcfdff;
    --bg: #fbfbfb;
    --bgTint: #f0e5ea;
    --bgGrey: #e6e8e8;
    --borderStyle: 1px solid var(--primary);
    --code: rgb(241, 212, 227);
    --textGrey: #737373;
  }
}

// Typography
$body: 'Jost', sans-serif;
$heading: 'Urbanist', serif;
$heading-light: 'Urbanist', serif;

// Font weights
$std: 400;
$bold: 700;
