.c-author-info {
  padding: var(--vr);
  background-color: var(--bgTint);
  margin-top: calc(var(--vr) * 2);

  p {
    @media (min-width: $md) {
      font-size: 1.2rem;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  p + p {
    margin-top: var(--vr);
  }

  @media (min-width: $md) {
    display: flex;
  }

  @media (min-width: $md) {
    padding: calc(var(--vr) * 2);
  }
}

.c-author-info__image {
  margin: 0 auto var(--vr) auto;
  max-width: rem(100px);
  border-radius: 50%;
  overflow: hidden;
  filter: grayscale(100%);

  @media (min-width: $md) {
    margin: 0 var(--vr) var(--vr) auto;
    align-self: flex-start;
    max-width: rem(150px);
  }
}

.c-author-info__heading {
  margin: 0 0 var(--vr) 0;
}
