:root {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  --textDecorationColor: var(--textColor);

  @media (prefers-color-scheme: dark) {
    --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
    --textDecorationColor: var(--primary);
  }
}

.th-dark {
  --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  --textDecorationColor: var(--primary);
}

.th-light {
  @media (prefers-color-scheme: dark) {
    --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
    --textDecorationColor: var(--textColor);
  }
}

.o-richtext {
  padding-top: calc(var(--vr) * 2);

  > * + * {
    margin-top: var(--vr);
  }

  a {
    text-decoration-color: var(--textDecorationColor);

    &[href^='http'] {
      padding-right: 0.7em;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.15em;
        background-size: 100%;
        background-image: var(--externalLinkImage);
        transform: translateY(0.2em);
      }
    }
  }

  h2 + h3 {
    margin-top: var(--vr);
  }

  img {
    background: var(--bgTint);
  }

  pre {
    display: block;
    padding: 1rem;
    max-width: 60rem;
    font-size: 0.8em;
    margin: var(--vr) 0;

    * {
      margin-top: 0;
    }
  }

  code,
  kbd {
    border-radius: 0.25rem;
    background: var(--code);
    color: inherit;
    font-size: inherit;

    @media (min-width: $md) {
      font-size: 0.75em;
    }
  }

  kbd {
    background: var(--bgTint);
    padding: 0.1em 0.6em;
  }

  pre code {
    background: transparent;
    padding: 0;
    border-radius: 0;
    color: $white;
    font-weight: 400;
    font-size: 0.86em;
  }

  figure {
    margin-block: var(--vr);

    img {
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.18);
    }
  }

  :is(figure, aside) + * {
    margin-top: calc(var(--vr) * 2);
  }

  figcaption {
    margin: calc(var(--vr) / 2) 0 0 0;
  }

  li + li {
    margin-top: calc(var(--vr) / 2);
  }

  h2,
  h3 {
    margin-bottom: 0;
    margin-top: 0;
  }

  > * + :is(h2, h3, aside, figure, p:has(:first-child.o-hotlink)) {
    margin-top: calc(var(--vr) * 2);
  }

  h3 + * {
    margin-top: var(--vr);
  }

  iframe {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  blockquote {
    border-left: 4px solid var(--primary);
    background-color: var(--bgTint);
    padding: var(--vr);
  }

  aside {
    font-family: $heading-light;
    padding: var(--vr);
    font-size: 0.9rem;
    background-color: var(--bgTint);
    border-radius: 0.3rem;
    border: var(--borderStyle);
    line-height: 1.6;
    position: relative;

    > * + * {
      margin: 0.8rem 0 0 0;
    }

    a {
      &:hover,
      &:focus {
        cursor: pointer;
        color: var(--textColor);
      }
    }

    &::before {
      position: absolute;
      background-color: var(--primary);
      color: $black;
      border-radius: 0.1rem;
      padding: 0 0.5rem;
      font-size: 0.9em;
    }

    &::before {
      content: '<aside>';
      top: -1rem;
      left: 1rem;
    }

    @media (min-width: $md) {
      font-size: rem(18px);
    }
  }

  ul {
    padding-left: rem(20px);

    li {
      position: relative;
      padding-left: 1rem;

      @media (min-width: $md) {
        padding-left: 1.5rem;
      }
    }

    @media (min-width: $md) {
      padding-left: rem(40px);
    }
  }

  @media (min-width: $lg) {
    padding-top: 0;
  }

  .o-hotlink {
    &[href^='http'] {
      padding: 0.25em 1em;

      &::after {
        position: relative;
      }
    }
  }
}
