.o-hotlink {
  display: inline-flex;
  background-color: var(--primary);
  border-radius: 0.5rem;
  border: 0.1rem solid var(--primary);
  padding: 0.25em 1em;
  text-decoration: none;
  color: var(--white);
  transition:
    color 150ms,
    background-color 150ms;

  &[href^='http'] {
    &::after {
      --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='white' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='white' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
      position: relative;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: var(--bg);
    color: var(--primary);
    text-decoration: none;

    &[href^='http'] {
      &::after {
        --externalLinkImage: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath fill='deeppink' d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath fill='deeppink' d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
      }
    }
  }
}
