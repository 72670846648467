.c-post-grid-pagination__items {
  display: flex;
  list-style: none;
  margin: rem(20px) 0;
  font-family: $heading;
  padding: rem(20px) 0 0;
  border-top: 0.1rem solid var(--textColor);

  @media (min-width: $lg) {
    margin-top: rem(60px);
  }

  a {
    color: inherit;
    font-weight: 400;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--primary);
    }
  }
}

.c-post-grid-pagination__item--next {
  margin-left: auto;
}
