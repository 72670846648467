.c-alt-header {
  --py: clamp(3rem, 10vw, 20rem);
  background: linear-gradient(
    -45deg,
    var(--color2, deeppink),
    var(--color1, darkorchid)
  );
  background-attachment: fixed;
  padding: var(--py) 0 var(--vr) 0;
  color: var(--headerTextColor, var(--white));

  .c-tag-links__tag a {
    color: var(--white);
    background-color: var(--headerBg);
  }

  .c-tag-links__tag--note a,
  .c-tag-links__tag--quick-tip a {
    background-color: var(--note, royalblue);
    color: var(--white);
  }

  .c-tag-links__tag--quick-tip a {
    background-color: var(--note, turquoise);
    color: var(--black);
  }

  .c-tag-links__tag--demo a {
    background-color: var(--note, orange);
    color: var(--black);
  }

  @media (min-width: $lg) {
    margin-bottom: calc(var(--vr) * 2);
  }
}
