.c-post-header {
  padding-top: var(--vr);
  margin-bottom: calc(var(--vr) * 2);

  > * + * {
    margin: rem(5px) 0 0;

    @media (min-width: $md) {
      margin: rem(10px) 0 0;
    }
  }

  @media (min-width: $md) {
    margin-bottom: var(--vr);
  }

  @media (min-width: $lg) {
    @supports (display: grid) {
      margin-bottom: 0;
    }
  }
}

.c-post-header__date,
.c-post-header__source {
  display: block;
  font-family: $heading-light;
  font-size: 0.9rem;

  @media (min-width: $md) {
    font-size: 1rem;
  }
}

.c-post-header__series {
  display: block;
  font-size: rem(16px);
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (min-width: $md) {
    font-size: rem(24px);
  }
}

.c-post-header__title {
  margin-bottom: 0;
  max-width: 25ch;
  letter-spacing: -0.01em;

  @media (min-width: $lg) {
    margin-bottom: var(--vr);
  }
}

.c-post-header {
  position: relative;
  margin-bottom: 0;
  padding-bottom: calc(var(--vr) * 2);
  border-bottom: 0.1rem solid var(--textColor);
}

.page-content--about {
  .c-post-header__title {
    @media (min-width: $lg) {
      margin-bottom: 0;
    }
  }
}
