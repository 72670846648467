.sidebar {
  margin-top: var(--vr);
  padding-top: var(--vr);
  border-top: 1px solid;

  @media (min-width: $lg) {
    margin-top: 0;
    padding-top: 0;
    border: none;
    position: sticky;
    top: 5rem;
  }
}

.sidebar__heading {
  font-family: $heading-light;
  font-size: 0.8em;
  font-weight: normal;
  margin-top: 0;
}

.sidebar__list {
  display: grid;
  gap: 1em;
  position: sticky;
  top: 0;
}

.sidebar__link {
  font-family: $heading;
  font-weight: $bold;
  line-height: 1.25;
}
