@font-face {
  font-family: 'Urbanist';
  src: url('../fonts/urbanist.woff2') format('woff2-variations');
  font-display: swap;
  font-weight: 100 800;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/jost.woff2') format('woff2-variations');
  font-display: swap;
  font-weight: 100 800;
}

html {
  @media (min-width: 100em) {
    font-size: 110%;
  }
}

body {
  font-family: $body;

  @media (min-width: $md) {
    font-size: 1.2rem;
  }

  @media (min-width: $lg) {
    font-size: 1.4rem;
  }
}

h1,
h2,
h3,
h4,
h5,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5 {
  font-family: $heading;
  font-weight: 700;
}

h1,
.heading-1 {
  font-weight: 800;
}

p,
ul,
ol {
  line-height: 1.6;
}

a {
  color: var(--textColor);
  transition:
    color 200ms,
    text-underline-offset 200ms,
    text-decoration-color 200ms;

  @supports (text-decoration-color: transparent) {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    text-decoration-thickness: 0.1em;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    text-underline-offset: 0.4em;
    color: var(--textColor);
    text-decoration-color: var(--primary);
    text-decoration-thickness: 0.1em;
    transition:
      color 200ms,
      text-underline-offset 200ms,
      text-decoration-color 200ms;
  }
}

pre {
  overflow: scroll;
  background-color: $bg-dark;
  color: $light-grey;
  padding: 1rem;
}

blockquote {
  padding-left: rem(20px);
  border-left: 8px solid var(--primary);

  @media (min-width: $md) {
    padding-left: rem(40px);
  }
}

code {
  padding: 0.125rem 0.25rem;
  border-radius: 0.2em;
  font-size: 80%;
}

h1,
.heading-1 {
  font-size: 1.8rem;
  margin-top: 0;
  line-height: 1.2;

  @media (min-width: $md) {
    font-size: 3rem;
  }

  @media (min-width: $lg) {
    font-size: 3.6rem;
  }
}

h2,
.heading-2 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;

  @media (min-width: 50em) {
    font-size: 2.8rem;
  }
}

h3,
.heading-3 {
  font-size: 1.3rem;
  font-weight: 700;

  @media (min-width: 42em) {
    font-size: 1.4rem;
  }

  @media (min-width: 50em) {
    font-size: 2.3rem;
  }
}

h4,
.heading-4 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3;

  @media (min-width: 50em) {
    font-size: 1.6rem;
  }
}

li > h3 {
  margin-top: 1.5rem;
}

figcaption {
  font-size: rem(16px);
  margin-top: 0.5rem;
}

small {
  font-size: 0.9rem;
}

p:empty {
  display: none;
}

.intro {
  font-size: 1.1em;
  font-weight: 500;
  margin-top: var(--vr);

  @media (min-width: $lg) {
    margin-bottom: var(--vr);
    margin-top: 0;
  }
}

.truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
